import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      ofertasPublicasCashback: imageSharp(fluid: { originalName: { regex:"/ofertas-publicas-cashback/" }}) {
        fluid(maxWidth: 457, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      } 
      vantagensInterInvestMobile: imageSharp(fluid: { originalName: { regex:"/vantagens-inter-invest-mobile/" }}) {
        fluid(maxWidth: 456, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      } 
      vantagensInterInvest: imageSharp(fluid: { originalName: { regex:"/vantagens-inter-invest/" }}) {
        fluid(maxWidth: 456, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      } 
      ofertasPublicasInterUm: imageSharp(fluid: { originalName: { regex:"/ofertas-publicas-inter-01/" }}) {
        fluid(maxWidth: 456, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      } 
      ofertasPublicasInterDois: imageSharp(fluid: { originalName: { regex:"/ofertas-publicas-inter-02/" }}) {
        fluid(maxWidth: 456, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      ofertasPublicasInterTres: imageSharp(fluid: { originalName: { regex:"/ofertas-publicas-inter-03/" }}) {
        fluid(maxWidth: 456, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      ofertasPublicasInterQuatro: imageSharp(fluid: { originalName: { regex:"/ofertas-publicas-inter-04/" }}) {
        fluid(maxWidth: 456, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      ofertasPublicasInterCinco: imageSharp(fluid: { originalName: { regex:"/ofertas-publicas-inter-05/" }}) {
        fluid(maxWidth: 456, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }      
    }
  `)

  return pageQuery
}

export default PageQuery
