import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Layout from 'src/layouts/BaseLayout'

import HeaderSection from './sections/ofertas-publicas-cashback/_header'
import OfertasPublicasDisponiveis from './sections/ofertas-publicas/_PublicOfferings'
import VantagensInterInvest from './sections/vantagens-inter-invest/_vantagens'
import EntendaSobreOfertasPublicas from './sections/entenda-sobre-ofertas-publicas/_entenda'
import FaqOfertasPublicas from './sections/faq-ofertas-publicas/_faq'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

const PublicOfferings = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <HeaderSection />
        <OfertasPublicasDisponiveis />
        <VantagensInterInvest />
        <EntendaSobreOfertasPublicas />
        <FaqOfertasPublicas faq={pageContext.structuredData.faq} />
        <DisclaimerInterInvest
          sendDatalayerEvent={sendDatalayerEvent}
          section='dobra_06'
        />
      </Layout>
    </Wrapper>
  )
}

export default PublicOfferings
