import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { DisclaimerDiv } from './style'

import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import InvestmentsLogos from '../InvestmentsLogos/_index'
interface IDisclaimerInterInvestProps {
  sendDatalayerEvent: (data: IDataLayerParams) => void;
  dataLayer?: IDataLayerParams;
  operationalLink?: string;
  section: string;
}

const DisclaimerInterInvest = ({ sendDatalayerEvent, dataLayer, operationalLink, section }: IDisclaimerInterInvestProps) => {
  const instabilityLink = 'https://marketing.bancointer.com.br/arquivos/pdf/idtvm/Invest-Documento-Procedimentos-1.pdf?_gl=1*1ujenlv*_gcl_au*MTU1MTg4ODAxNi4xNjg3MTc4NDEy&_ga=2.229489818.761707871.1688478086-1703710166.1684156661'
  const indicatorsLink = 'https://marketing.bancointer.com.br/arquivos/pdf/idtvm/211011-Invest-Documento-SLA-1.pdf?_gl=1*1ujenlv*_gcl_au*MTU1MTg4ODAxNi4xNjg3MTc4NDEy&_ga=2.229489818.761707871.1688478086-1703710166.1684156661'
  const remunerationLink = 'https://marketing.bancointer.com.br/arquivos/pdf/idtvm/informacoes-sobre-remuneracao-do-distribuidor-idtvm.pdf?_gl=1*r9of6p*_gcl_au*MTU1MTg4ODAxNi4xNjg3MTc4NDEy&_ga=2.235191687.761707871.1688478086-1703710166.1684156661'

  return (
    <DisclaimerDiv className='bg-grayscale--100 py-5'>
      <div className='container py-lg-4'>
        <div className='row'>
          <div className='col-12 align-items-center d-flex'>
            <div className='icon'>
              <OrangeIcon size='MD' color='#FF7A00' icon='attention' />
            </div>
            <div>
              <p className='fs-16 lh-19 fw-400 text-grayscale--400 mb-3'>
                Toda comunicação através da rede mundial de computadores está sujeita a interrupções ou
                atrasos, podendo impedir ou prejudicar o envio de ordens ou a recepção de informações
                atualizadas. A Inter DTVM, exime-se de responsabilidade por danos sofridos por seus
                clientes decorrentes de falha de serviços disponibilizados por terceiros, incluindo,
                mas não se limitando, à aqueles conexos à rede mundial de computadores. Em caso de
                indisponibilidade da ferramenta de negociação on-line, as negociações deverão ser
                realizadas por telefone diretamente com a Mesa de Operações por meio dos nossos canais
                de atendimento.
              </p>

              <p className='fs-16 lh-19 fw-400 text-grayscale--400 mb-3'>
                Consulte informações sobre os procedimentos adotados em caso de instabilidade ou
                indisponibilidade de plataformas de negociação
                <a
                  href={instabilityLink}
                  target='_blank' rel='noreferrer'
                  className='fw-700 text-orange--extra'
                  onClick={() => {
                    sendDatalayerEvent({
                      ...dataLayer,
                      element_name: 'neste documento',
                      redirect_url: instabilityLink,
                      section: section,
                    })
                  }}
                >
                  {' '}neste documento
                </a>.
              </p>

              <p className='fs-16 lh-19 fw-400 text-grayscale--400 mb-3'>
                Consulte os indicadores de níveis de serviço de atendimento e latência das plataformas
                <a
                  href={indicatorsLink}
                  target='_blank' rel='noreferrer'
                  className='fw-700 text-orange--extra'
                  onClick={() => {
                    sendDatalayerEvent({
                      ...dataLayer,
                      element_name: 'neste documento',
                      redirect_url: indicatorsLink,
                      section: section,
                    })
                  }}
                >
                  {' '}neste documento
                </a>.
              </p>

              <p className='fs-16 lh-19 fw-400 text-grayscale--400 mb-31'>
                Este material não leva em consideração os objetivos de investimento, a situação
                financeira e as necessidades específicas de qualquer investidor em particular.
                Os produtos apresentados podem não ser adequados para todos os investidores.
                Antes de investir verifique as características dos produtos e sempre invista de
                acordo com o seu perfil de investidor (suitability).
              </p>

              <p className='fs-16 lh-19 fw-400 text-grayscale--400'>
                A instituição é remunerada pela distribuição do produto. Para maiores detalhes,
                consulte as informações sobre a
                <a
                  href={remunerationLink}
                  target='_blank' rel='noreferrer'
                  className='fw-700 text-orange--extra'
                  onClick={() => {
                    sendDatalayerEvent({
                      ...dataLayer,
                      element_name: 'remuneração do distribuidor',
                      redirect_url: remunerationLink,
                      section: section,
                    })
                  }}
                >
                  {' '}remuneração do distribuidor
                </a>.
              </p>

              <p className='fs-16 lh-19 fw-400 text-grayscale--400'>
                Os custos operacionais aqui disponibilizados foram atualizados pela última vez em 22/12/2023. Para mais informações, consulte o
                <a
                  href={operationalLink}
                  target='_blank' rel='noreferrer'
                  className='fw-700 text-orange--extra'
                  onClick={() => {
                    sendDatalayerEvent({
                      ...dataLayer,
                      element_name: 'link',
                      redirect_url: operationalLink,
                      section: section,
                    })
                  }}
                >
                  {' '} link
                </a>.
              </p>
            </div>
          </div>
        </div>
        <InvestmentsLogos classes='d-flex flex-wrap justify-content-center mt-3' />
      </div>
    </DisclaimerDiv>
  )
}

DisclaimerInterInvest.defaultProps = {
  operationalLink: 'https://static.bancointer.com.br/idtvm/home/files/b901066a474a448f8c680040460a955c_politica-de-corretagem-inter-dtvm.pdf?_gl=1*wcgwfj*_gcl_au*MTA4ODE0NzM2My4xNzAzODUyNjc1',
}

export default DisclaimerInterInvest
