/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable react/jsx-no-bind */
import React, { useState, MouseEvent, useEffect } from 'react'

import InputRange from 'react-input-range'
import { convertFloatToCurrencyString } from 'src/shared/helpers'

import PublicOfferingsJSON from 'src/assets/data/ofertas-publicas/ofertas.json'
import PublicOffersCards from '../_PublicOfferingsCards'

import { BoxFilterContainer, ButtonFilter, BoxFilterWhite, ButtonsFilterWhite, ButtonClear, ButtonFilterOrange, Slider } from './style'

type OffersProps = {
  type: string;
  searchText: string;
}

const FilterOffers = ({ type, searchText }: OffersProps) => {
  const ButtonsOffers = Object.keys(PublicOfferingsJSON).map((item: string) => item)
  const [ isModalOpen, setIsModalOpen ] = useState('none')
  const [ isOffers, setIsOffers ] = useState(type)
  const [ isActive, setIsActive ] = useState(0)
  const [ typeOrdering, setTypeOrdering ] = useState('')
  const [ typeOffers, setTypeOffers ] = useState<string[]>([])
  const [ filterOrdering, setFilterOrdering ] = useState('')
  const [ filterTypeOffers, setFilterTypeOffers ] = useState<string[]>([])
  const [ searchClear, setSearchClear ] = useState(searchText)
  const minValue = 0
  const maxValue = 25000

  const [ inputValue, setInputValue ] = useState({
    min: 5,
    max: 25000,
  })

  const [ filterMinMax, setFilterMinMax ] = useState({
    min: 0,
    max: 25000,
  })

  function OpenModal () {
    setIsModalOpen('block')
    setSearchClear('')
  }

  useEffect(() => {
    setSearchClear(searchText)
  }, [ searchText ])

  function cLoseModal () {
    setIsModalOpen('none')
  }

  function handleClick (evt: MouseEvent<HTMLButtonElement>, index: number) {
    evt.preventDefault()
    setIsActive(index)
    if (index === 0) {
      setIsOffers('disponiveis')
    } else {
      setIsOffers('encerradas')
    }
  }

  function handleTypeOrdering (evt: MouseEvent<HTMLButtonElement>) {
    const elementButton = evt.target as HTMLButtonElement
    const buttonOrdering = elementButton.innerText
    setTypeOrdering(buttonOrdering)
  }

  function handleTypeOffers (evt: MouseEvent<HTMLButtonElement>) {
    const elementButton = evt.target as HTMLButtonElement
    const buttonOffer = elementButton.innerText
    if (typeOffers.includes(buttonOffer)) {
      const filtered = typeOffers.filter((item: string) => item !== buttonOffer)
      setTypeOffers(filtered)
    } else {
      setTypeOffers([
        ...typeOffers,
        buttonOffer,
      ])
    }
  }

  function handleClear () {
    setTypeOrdering('')
    setTypeOffers([])
    setFilterOrdering('')
    setFilterTypeOffers([])
    setInputValue({
      min: 5,
      max: 25000,
    })
  }

  function handleFilter () {
    setIsModalOpen('none')
    setFilterOrdering(typeOrdering)
    setFilterTypeOffers(typeOffers)
    setFilterMinMax(inputValue)
  }

  const handleChangeRange = (value: { min: number; max: number }) => {
    setInputValue(value)
  }

  return (
    <>
      <div className='col-12 px-0 px-md-3 mb-4 pb-md-3'>
        <ButtonFilter
          className='fs-12 lh-15 text-left'
          onClick={OpenModal}
        >
          Filtros
        </ButtonFilter>

        {ButtonsOffers.map((item: string, index: number) => (
          <ButtonFilter
            key={index}
            className={`fs-12 lh-15 white ${isActive === index ? 'active' : ''}`}
            onClick={(evt: MouseEvent<HTMLButtonElement>) => handleClick(evt, index)}
          >
            {item}
          </ButtonFilter>
        ))}
      </div>

      <BoxFilterContainer modalOpen={isModalOpen} onClick={cLoseModal} />
      <div className='col-12'>
        <BoxFilterWhite className='text-grayscale--500' modalOpen={isModalOpen}>
          <li className='fs-18 lh-22'>
            <span className='border-bottom fw-600'>Filtros</span>
          </li>
          <li className='text-grayscale--400 fs-16 lh-20 border-bottom'>
            <span className='FW-600'>Ordenação</span>
          </li>
          <li>
            <ButtonsFilterWhite
              onClick={handleTypeOrdering}
              className={typeOrdering.includes('Investimento mínimo') ? 'active' : ''}
            >Investimento mínimo
            </ButtonsFilterWhite>
            <ButtonsFilterWhite
              onClick={handleTypeOrdering}
              className={typeOrdering.includes('Período de reserva') ? 'active' : ''}
            >Período de reserva
            </ButtonsFilterWhite>
          </li>
          <li className='text-grayscale--400 fs-16 lh-20 border-bottom'>
            Tipo de Oferta
          </li>
          <li>
            <ButtonsFilterWhite
              onClick={handleTypeOffers}
              className={typeOffers.includes('Renda Fixa') ? 'active' : ''}
            >Renda Fixa
            </ButtonsFilterWhite>
            <ButtonsFilterWhite
              onClick={handleTypeOffers}
              className={typeOffers.includes('Fundos Imobiliários') ? 'active' : ''}
            >Fundos Imobiliários
            </ButtonsFilterWhite>
            <ButtonsFilterWhite
              onClick={handleTypeOffers}
              className={`mt-2 ${typeOffers.includes('Ações') ? 'active' : ''}`}
            >Ações
            </ButtonsFilterWhite>
            <ButtonsFilterWhite
              onClick={handleTypeOffers}
              className={`mt-2 ${typeOffers.includes('Outras') ? 'active' : ''}`}
            >Outras
            </ButtonsFilterWhite>
          </li>
          <li className='text-grayscale--400 fs-16 lh-20 border-bottom mb-1'>
            <span className='FW-600'>Aplicação mínima</span>
          </li>
          <li>
            <Slider>
              <InputRange
                draggableTrack
                minValue={minValue}
                maxValue={maxValue}
                onChange={value => handleChangeRange(value)}
                onChangeComplete={value => (value)}
                value={inputValue}
              />
            </Slider>
            <span className='fs-12 lh-15 text-grayscale--400 d-block pl-2'>
              De R$ {convertFloatToCurrencyString(inputValue.min)} a R$ {convertFloatToCurrencyString(inputValue.max)}
            </span>
          </li>
          <li className='text-right pt-4 mb-0'>
            <ButtonClear
              className='fs-14 lh-17 fw-700'
              title='Limpar'
              onClick={handleClear}
            >
              Limpar
            </ButtonClear>
            <ButtonFilterOrange
              className='fs-14 lh-17 ml-3 fw-700'
              title='Filtrar'
              onClick={handleFilter}
            >
              Filtrar
            </ButtonFilterOrange>
          </li>
        </BoxFilterWhite>
      </div>

      <PublicOffersCards
        searchText={searchClear} type={isOffers}
        typeOrdering={filterOrdering} typeOffers={filterTypeOffers}
        minMaxValueFilter={filterMinMax}
      />
    </>
  )
}

export default FilterOffers
