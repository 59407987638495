import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  min-height: 200px;

  @media ${device.tablet} {
    align-items: center;
    min-height: 200px;
  }

  @media ${device.desktopLG} {
    min-height: 200px;
  }

  .card-gray {
    min-height: 330px;
    padding: 30px 16px;

    @media ${device.tablet} {
      min-height: 235px;
    }

    @media ${device.desktopLG} {
      padding: 24px;
      min-height: 260px;
    }
  }

  @media ${device.tablet} {
    .react-multiple-carousel__arrow--left {
      left: calc(25% + 1px);
    }
    .react-multiple-carousel__arrow--right {
      right: calc(25% + 1px);
    } 
    .react-multi-carousel-dot {
      button {
        margin-bottom: 10px;
      }
    }
  }
`
