import React, { useEffect, useRef } from 'react'
import { animated, useTrail } from 'react-spring'
import Img from 'gatsby-image'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Home from '@interco/icons/build-v4/orangeds/MD/home'

import headerJson from '../../header.json'

import { Link } from 'gatsby'
import ScrollTo from 'src/components/ScrollTo'
import ToolTip from '../../ToolTip/_ToolTip'

import { ContentHeader } from './style'

import useScroll from 'src/hooks/window/useScroll'
import isVisible from 'src/utils/isVisible'

import usePageQuery from '../../pageQuery'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const OfertasPublicasCashback = () => {
  const [ animatedHeader, setAnimatedHeader ] = useTrail(3, () => (fadeFrom))
  const headerRef = useRef<HTMLDivElement>(null)
  const data = usePageQuery()
  const scroll = useScroll(300)

  useEffect(() => {
    if (isVisible(headerRef.current, -0.3)) {
      setAnimatedHeader((fadeTo))
    }
  }, [ scroll ])

  return (
    <ContentHeader
      ref={headerRef} id='ofertas-publicas-com-cashback'
      className='bg-white py-5 d-flex position-relative justify-content-md-between align-items-center'
    >
      <div className='container'>
        <div className='row justify-content-center justify-content-md-between align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 order-md-last mb-2 mb-md-0'>
            <Img fluid={data.ofertasPublicasCashback.fluid} alt='Ofertas públicas com cashback' className='float-md-right' />
          </div>
          <div className='col-12 col-md-6 mt-4 mt-md-0'>
            <div className='d-md-block'>
              <div className='bread'>
                <Link
                  to='/'
                  className='d-md-inline'
                ><Home height={24} width={24} color='#6A6E81' />
                </Link>
                <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
                <Link
                  to='/pra-voce/investimentos/'
                  className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
                >
                  {headerJson.breadcrumb[0]}
                </Link>
                <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
                <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{headerJson.breadcrumb[1]}</p>
              </div>
            </div>
            <animated.h1 style={animatedHeader[0]} className='mt-3 fs-32 lh-37 fs-lg-40 lh-lg-47 fs-xl-48 lh-xl-56 fw-600 text-grayscale--500 mt-md-4'>
              Ofertas Públicas com cashback em Pontos Loop? No Inter tem!
            </animated.h1>
            <animated.p style={animatedHeader[1]} className='fs-18 lh-22 text-grayscale--500 mt-3 pr-xl-5'>
              Participe de Ofertas Públicas de Valores Mobiliários e tenha a possibilidade de cashback em Pontos Loop direto na sua conta. <span className='position-relative'><ToolTip classNameToolTip='tool-tip-header' /></span>
            </animated.p>
            <ScrollTo
              to='#ofertas-publicas'
              section='dobra_01'
              sectionName='Ofertas Públicas com cashback? No Inter tem!'
              elementName='Conheça as Ofertas disponíveis'
            >
              <animated.button
                style={animatedHeader[2]} data-home-ofertas-publicas='hero'
                className='fs-14 btn text-white btn--lg btn-orange--extra text-none rounded-3 mt-2 fw-700'
              >Conheça as Ofertas disponíveis
              </animated.button>
            </ScrollTo>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default OfertasPublicasCashback
