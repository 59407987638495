import React from 'react'

// Components
import FAQ from 'src/components/Faq'

// Styles
import { SectionWrapper } from './style'

type Faq = {
  faq: {question: string; answer: string}[];
}

const FaqOfertasPublicas = (props: Faq) => {
  return (
    <SectionWrapper id='faq' className='bg-white py-5'>
      <div className='container animated'>
        <div className='row justify-content-center justify-content-md-between align-items-center'>
          <div className='mb-4'>
            <h2 className='fs-24 lh-28 fs-md-40 lh-md-47 text-grayscale--500'>Ficou com alguma dúvida?</h2>
            <p className='text-grayscale--500 fs-16 lh-20'>
              Veja se podemos te ajudar com essas perguntas frequentes.
            </p>
          </div>
          <FAQ answerData={props.faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} />
        </div>
      </div>
    </SectionWrapper>
  )
}

export default FaqOfertasPublicas
