import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const ContentHeader = styled.section`
  min-height: calc(100vh - 64px);

  @media ${device.tablet} {
    align-items: center;
    min-height: 450px;
  }
  @media ${device.desktopLG} {
    min-height: 576px;
  }
  @media ${device.desktopXL} {
    min-height: calc(100vh - 73px);
  }

  .bread-crumb {
    span, a {
      color: ${grayscale['500']} !important;
      &:hover {
        color:  ${grayscale['400']} !important;
      }
    }
  }

  button, a {
    @media ${device.desktopLG} {
      max-width: 376px;
    }
    @media ${device.desktopXL} {
      max-width: 456px;
    }
  }
`

export const ScrollButton = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  width: auto;
`
