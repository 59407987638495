import React from 'react'
interface IInvestmentsLogos {
  classes: string;
}

const InvestmentsLogos = ({ classes }: IInvestmentsLogos) => {
  return (
    <div className={classes}>
      <div>
        <img
          src='https://central-imagens.bancointer.com.br/images-without-small-versions/Anbima/image.webp'
          alt='Logo Anbima' width={107} height={80}
        />
      </div>
      <div className='mx-4'>
        <img
          src='https://central-imagens.bancointer.com.br/images-without-small-versions/b3-logo/image.webp'
          alt='Logo B3' width={74} height={80}
        />
      </div>
      <div>
        <img
          src='https://central-imagens.bancointer.com.br/images-without-small-versions/como-investir-logo/image.webp'
          alt='Logo Como Investir' width={109} height={80}
        />
      </div>
    </div>
  )
}

export default InvestmentsLogos
