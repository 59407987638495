import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Tooltip = styled.div`
  background: ${grayscale['500']};
  border-radius: 8px;
  position: absolute;
  width: 279px;
  left: -178px;
  z-index: 999;
  padding: 20px;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.07);
  top: 30px;

  @media ${device.tablet} {
    left: -98px;
  }

  svg {
    vertical-align: none;
  }

  &:before {
    content: '';
    top: -4px;
    left: 177px;
    background: ${grayscale['500']};
    border-radius: 2px;
    height: 16px;
    position: absolute;
    width: 16px;
    transform: rotate(45deg);
  }

  &.tool-tip-header {
    left: -244px;

    @media ${device.tablet} {
      left: -130px;
    }

    &:before {
      left: 244px;

      @media ${device.tablet} {
        left: 130px;
      }
    }
  }

  &.tool-tip-vantagens {
    @media ${device.tablet} {
      left: -150px;
    }

    &:before {
      left: 182px;

      @media ${device.tablet} {
        left: 154px;
      }

      @media ${device.desktopLG} {
        left: 55%;
      }
    }
  }
`

export const ButtonToolTip = styled.span`
 
`
