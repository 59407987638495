import styled from 'styled-components'
import { grayscale, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

// Assets
import iconSearch from 'src/assets/images/svg/icon-search.svg'

export default styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  z-index: 1;
  
  @media(min-width: ${breakpoints.lg}) {
    margin-top: 35px;
    position: relative;
    right: 0;
    left: 0;
    z-index: 1;
  }

  input {
    border: none;
    border-radius: 8px;
    color: ${grayscale['500']};
    font-weight: 600;
    height: 52px;
    outline: 0;
    width: 100%;
    margin: 0 auto;
    display: block;

    &:placeholder-shown {
      background:${white} url(${iconSearch}) no-repeat;
      background-position: calc(50% - 130px);
      color: ${grayscale['300']};
      font-weight: 300;
      font-size: 14px;
    }
  }
`
