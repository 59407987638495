import React, { ChangeEvent, useRef, useState } from 'react'
import { useLocation } from '@reach/router'
import GeneralSearchStyle from './style'

type GeneralSearchProps = {
  initialValue?: string;
  styles?: string;
  setInputText: Function;
  placeholderText?: string;
}

const GeneralSearch = ({ initialValue, styles, setInputText, placeholderText }: GeneralSearchProps) => {
  const textInput = useRef(null)
  const [ searchText, setSearchText ] = useState(initialValue)
  const location = useLocation()

  const ofertasPublicas = location.pathname.includes('/pra-voce/investimentos/ofertas-publicas')

  function handleChange (evt: ChangeEvent<HTMLInputElement>) {
    const valueInput = evt.target.value
    setInputText(valueInput)
    setSearchText(valueInput)
    ofertasPublicas ? window.history.pushState('', '', `/pra-voce/investimentos/ofertas-publicas?&q=${encodeURIComponent(valueInput)}`)
    : window.history.pushState('', '', `/pra-voce/investimentos/fundos-de-investimento?&q=${encodeURIComponent(valueInput)}`)
  }

  return (
    <GeneralSearchStyle id='search-form' className={styles}>
      <input
        type='text'
        placeholder={placeholderText}
        className='px-3 text-center fs-16 fs-sm-14 lh-17'
        maxLength={150}
        onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
        value={searchText}
        ref={textInput}
      />
    </GeneralSearchStyle>
  )
}

GeneralSearch.defaultProps = {
  initialValue: '',
  styles: '',
}

export default GeneralSearch
