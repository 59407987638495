import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, white, green } from 'src/styles/colors'

export const SectionWrapper = styled.section`
  align-items: center;

  nav {
    ul {
      justify-content: start !important;
      
      @media ${device.tablet} {
        margin-bottom: 41px;
      }

      @media ${device.desktopXL} {
        margin-bottom: 57px;
      }

      li {
        border-radius: 20px;
        background: ${white};
        border: 1px solid ${grayscale['200']};
        color: ${grayscale['500']};
        line-height: 15px !important;
        height: 33px;
        margin: 0 4px;

        @media ${device.tablet} {
          margin: 0 12px;
        }
        
        button {
          font-size: 12px;
          line-height: 15px;
          height: 31px;
          padding: 0 16px 2px 16px;
          text-transform: capitalize;
        }       
      }
    }
  }
`
export const BoxOffers = styled.div`
  border: 1px solid ${grayscale['200']};
  box-sizing: border-box;
  border-radius: 16px;
  min-height: 300px;
  margin-bottom: 24px;

  @media ${device.tablet} {
    margin-left: 12px;
    margin-right: 12px;
    max-width: 335px;
    min-height: 350px;
    float: left;
    align-items: center;
    position: relative;
    display: flex;
  }  
  
  @media ${device.desktopLG} {
    max-width: 295px;
  }
  
  @media ${device.desktopXL} {
    max-width: 264px;
  }

  @media ${device.desktopXXXL} {
    max-width: 336px;
  }

  .height-title {
    
    @media ${device.tablet} {
      max-height: 95px;
    }
  }

  .tag {
    background: ${green.dark};
    border-radius: 0 16px;
    width: 96px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: calc(100% - 96px);
  }

  .icon {
    height: 33px;
    width: 33px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
`

export const LimitPage = styled.div`
  button {
    align-items: center;
    background: ${white};
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    border: 0;
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
    position: relative;
    margin: 0 auto;
    outline: none;
  }
`
