import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import OpenModal from 'src/components/OpenModal'
import Icon from 'src/components/UI/MarkdownIcon'
import ToolTip from '../../ToolTip/_ToolTip'

import { SectionWrapper } from './style'

const VantagensInterInvest = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionWrapper className='d-flex align-items-end align-items-md-center pb-5 pb-md-0'>
      <div className='container'>
        <div className='row align-items-center justify-content-lmd-between'>
          <div className='col-12 col-md-6 offset-md-6 pl-md-5 pl-lg-3 pl-xl-0 col-xl-5 offset-xl-7'>
            <h3 className='fs-24 lh-28 fs-lg-32 lh-lg-37 fs-xl-40 lh-xl-47 text-grayscale--500'>
              <span className='d-xl-block'>Aproveite as</span>
              <span className='d-xl-block'> vantagens da</span> Inter Invest
            </h3>
            <div className='d-flex ml-n2 mb-4 align-items-center'>
              <div className='col-1 mr-3'><Icon width='24' height='24' icon='pagamentos/cashback-01' directory='v2' color='orange--base' /></div>
              <p className='fs-16 lh-20 text-grayscale--500 fw-600 mb-0'>
                <span className='d-xl-block'>Possibilidade de cashback em Pontos Loop para clientes </span>Black e WIN
                <span className='position-relative'> <ToolTip classNameToolTip='tool-tip-vantagens' /></span>
              </p>
            </div>
            <div className='d-flex ml-n2 mb-4 align-items-center'>
              <div className='col-1 mr-3'><Icon width='24' height='24' icon='products/interinvest' directory='v2' color='orange--base' /></div>
              <p className='fs-16 lh-20 text-grayscale--500 fw-600 mb-0'>
                Tenha acesso às principais ofertas públicas do mercado
              </p>
            </div>
            <div className='d-flex ml-n2 mb-4 align-items-center'>
              <div className='col-1 mr-3'><Icon width='24' height='24' icon='categories/diversos' directory='v2' color='orange--base' /></div>
              <p className='fs-16 lh-20 text-grayscale--500 fw-600 mb-0'>
                Área de conteúdos exclusiva
              </p>
            </div>
            <div className='d-flex ml-n2 mb-4 align-items-center'>
              <div className='col-1 mr-3'><Icon width='24' height='24' icon='action/smartphone' directory='v2' color='orange--base' /></div>
              <p className='fs-16 lh-20 text-grayscale--500 fw-600 mb-0'>
                Invista de forma simples pelo app
              </p>
            </div>
            <div className='col-12 px-0'>
              <OpenModal to='open-your-account-form'>
                <button
                  data-home-ofertas-publicas='vantagens'
                  className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none mt-4'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_04',
                      element_action: 'click button',
                      element_name: 'Quero Investir',
                      section_name: 'Aproviete as vantagens da Inter Invest',
                    })
                  }}
                >
                  Quero Investir
                </button>
              </OpenModal>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default VantagensInterInvest
