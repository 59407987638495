import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'

// Images
import vantagensInterInvestMobile from '../../assets/images/vantagens-inter-invest-mobile.jpg'
import vantagensInterInvestLG from '../../assets/images/vantagens-inter-invest-desktop-lg.png'
import vantagensInterInvestXL from '../../assets/images/vantagens-inter-invest-desktop-xl.png'

export const SectionWrapper = styled.section`
  background: ${white} url(${vantagensInterInvestMobile}) no-repeat top center;
  background-size: contain;
  background-position: 0 -90px;
  min-height: 800px;
  
  @media (min-width: 320px) and (orientation: landscape) {
    background: none;
    padding-top: 3rem;
  }
  @media ${device.tablet} {
    background: ${white} url(${vantagensInterInvestLG}) no-repeat left center;
    background-size: contain;
    background-position: -60px 0;
    min-height: 460px;
    padding-top: 0;  
  }
  @media ${device.desktopLG} {
    background: ${white} url(${vantagensInterInvestLG}) no-repeat left center;
    background-size: contain;
    background-position: -20px 0;
    min-height: 501px;
  }
  @media ${device.desktopXL} {
    background: ${white} url(${vantagensInterInvestXL}) no-repeat left center;
    background-size: contain;
    background-position: -160px 0;
    min-height: 600px;
  } 
  @media ${device.desktopXXXL} {
    background-position: 0;
  } 
`
