import React, { useState } from 'react'

import useWidth from 'src/hooks/window/useWidth'
import Help from 'inter-frontend-svgs/lib/orangeds/MD/help'

import { orange } from 'src/styles/colors'
import { Tooltip, ButtonToolTip } from './style'
interface IToolTipProps {
  classNameToolTip: string;
}

const ToolTip = ({ classNameToolTip }: IToolTipProps) => {
  const [ open, setOpen ] = useState(false)

  const WIDTH_LG = 1024
  const windowWidth = useWidth(300)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      { windowWidth >= WIDTH_LG
        ? (
          <ButtonToolTip onClick={() => handleClick()}>
            <Help color={orange.extra} width={16} height={16} />
          </ButtonToolTip>
          )
        : (
          <ButtonToolTip onClick={() => setOpen(true)}>
            <Help color={orange.extra} width={16} height={16} />
          </ButtonToolTip>)
      }

      {open &&
        <Tooltip className={`tooltip ${classNameToolTip}`}>
          <p className='fs-12 lh-15 fw-700 text-white mb-1'>Cashback nas Ofertas Públicas</p>
          <p className='fs-12 lh-15 fw-400 text-white text-left'>Clientes Black tem direito a 20% e clientes WIN tem direito a 40% da remuneração de distribuição recebida pelo Inter como cashback, direto em suas contas.</p>
          <p className='fs-12 lh-15 fw-400 text-white text-left'>O cashback só é distribuído caso o valor totalize no mínimo R$ 10,00 por oferta pública aderida.</p>
          <span className='d-block fs-12 lh-15 fw-700 text-orange--extra text-right' onClick={() => setOpen(false)} title='Entendi'>Entendi</span>
        </Tooltip>
      }
    </>
  )
}

export default ToolTip
