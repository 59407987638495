import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  min-height: 200px;

  @media ${device.tablet} {
    align-items: center;
    min-height: 200px;
  }

  @media ${device.desktopLG} {
    min-height: 200px;
  }
`
