import React, { useEffect, useRef } from 'react'
import { animated, useTrail } from 'react-spring'
import Img from 'gatsby-image'

// Components
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import CarouselData from './data/carousel-data.json'

// Hooks
import useScroll from 'src/hooks/window/useScroll'
import isVisible from 'src/utils/isVisible'

// Style
import { SectionWrapper } from './style'

// Assets
import usePageQuery from '../../pageQuery'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

type CarouselProps = {
  image: string;
  title: string;
  description: string;
}

const EntendaSobreOfertasPublicas = () => {
  const data = usePageQuery()
  const [ animatedRead, setAnimatedRead ] = useTrail(2, () => (fadeFrom))
  const readRef = useRef<HTMLDivElement>(null)
  const scroll = useScroll(300)

  useEffect(() => {
    if (isVisible(readRef.current, -0.3)) {
      setAnimatedRead((fadeTo))
    }
  }, [ scroll ])

  return (
    <SectionWrapper ref={readRef} id='cashback-com-rentabilidade' className='bg-white py-5'>
      <div className='container pt-xl-5'>
        <div className='row justify-content-center justify-content-md-between align-items-center'>
          <div className='col-12'>
            <animated.h2
              style={animatedRead[0]}
              className='fs-24 lh-28 fs-lg-32 lh-lg-37 fs-xl-40 lh-xl-47 fw-600 text-grayscale--500 text-center'
            >
              <span className='d-md-block'>Entenda um pouco mais</span> sobre as Ofertas Públicas
            </animated.h2>
          </div>
          <animated.div style={animatedRead[1]} className='col-12 col-lg-10 col-xl-9 mx-lg-auto'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
            >
              {
                CarouselData.map((item: CarouselProps, index: number) => (
                  <div key={index} className='d-md-flex mb-lg-4 justify-content-md-between'>
                    <div className='col-md-6 mt-4'>
                      <Img fluid={data[item.image].fluid} alt={item.title} />
                    </div>
                    <div className='col-md-6 card-gray bg-grayscale--100 rounded-5 mt-4'>
                      <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--500 fw-600'>{item.title}</p>
                      <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--500' dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                  </div>
                ))
              }
            </DefaultCarousel>
          </animated.div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default EntendaSobreOfertasPublicas
