import React, { useContext, ReactNode, MouseEvent } from 'react'
import { ModalContext } from 'src/hoc/contexts'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

type OpenModalProps = {
  children: ReactNode;
  to: string;
  className?: string;
  onClick?: Function;
  typeDataLayer?: string;
  dataLayer?: IDataLayerParams;
}

type ContextModalProps = {
  [param: string]: {
    modal: boolean;
    setModal: <T>(value: T) => void;
    isSetDataLayer: Function;
    setDataLayerContext?: (value: IDataLayerParams) => void;
  };
}

function OpenModal ({ children, to, className, onClick, typeDataLayer, dataLayer }: OpenModalProps) {
  const contextModal: ContextModalProps = useContext(ModalContext)

  function openModal (evt: MouseEvent<HTMLSpanElement>) {
    evt.preventDefault()
    contextModal[to].setModal(true)
    contextModal[to].isSetDataLayer(() => typeDataLayer)
    dataLayer && contextModal[to].setDataLayerContext(dataLayer)

    if (onClick) {
      onClick()
    }
  }

  return <span className={className} onClick={openModal}>{children}</span>
}

OpenModal.defaultProps = {
  children: <></>,
  to: '',
}

export default OpenModal
